import { Container, PageNotFoundSection } from '@brick/ui-components';
import { Stack } from '@chakra-ui/react';
import { LayoutPopulated } from 'apps/client/components/LayoutPopulated/LayoutPopulated';
import { SECTION_SPACING } from 'apps/client/lib/const/const';

const NotFoundPage = () => {
  return (
    <LayoutPopulated seo={{ pageName: 'Stránka nebola nájdená' }}>
      <Stack spacing={SECTION_SPACING} pb={SECTION_SPACING} alignItems="center">
        <Container py={{ base: 32, md: 14 }}>
          <PageNotFoundSection />
        </Container>
        {/* <ServicesBannersSection {...servicesBannersSectionContent} /> */}
      </Stack>
    </LayoutPopulated>
  );
};

export default NotFoundPage;
